<template>
  <div class="middle-top">
    <a-row>
      <a-col :span="22">
        <a-form-model layout="inline" class="query-form" style="margin-top: -20px">
          <a-form-model-item label="" prop="monitorpointnum" class="self-select">
            <a-row>
              <a-col :span="12" class="middle-top-title"><a-button type="link" @click="gotoMonitorPage('monitorinfo')" style="font-size: 18px;color: white;font-weight: bold">监控端数量：<span style="color: blue">{{mainData.clientnum+mainData.devclientnum}}</span></a-button></a-col>
            </a-row>
            <a-row>
              <a-col :span="12" class="middle-top-title"><a-button type="link" @click="gotoMonitorPage('offline')" style="font-size: 18px;color: white;font-weight: bold">离线监控端数量：<span style="color: red">{{mainData.offclientnum+mainData.offdevclientnum}}</span></a-button></a-col>
            </a-row>
          </a-form-model-item>
          <a-form-model-item label="" prop="unhandlealarm" class="self-select">
            <a-row>
              <span class="middle-top-title" style="color: white">网络监测</span>
            </a-row>
            <a-row>
              <a-input v-model="netMonitor" :read-only="true" @click="networkDetail"></a-input>
            </a-row>
          </a-form-model-item>
          <a-form-model-item label="" prop="unhandlealarm" class="self-select" style="margin-left: 0%;width: auto">
            <a-row>
              <a-col :span="12" class="middle-top-title"><a-button type="link" @click="gotoEventPage('alarm')" style="font-size: 18px;color: white;font-weight: bold">未处理报警数量：<span style="color: red">{{unhandleData.unhandlealarm}}</span></a-button></a-col>
            </a-row>
            <a-row>
              <a-col :span="12" class="middle-top-title"><a-button type="link" @click="gotoEventPage('abnormal')" style="font-size: 18px;color: white;font-weight: bold">未处理异常数量：<span style="color: red">{{unhandleData.unhandleevent}}</span></a-button></a-col>
            </a-row>
          </a-form-model-item>
        </a-form-model>
      </a-col>
      <a-col :span="2">
        <div v-if="userInfo.rolename=='项目调度员'||userInfo.rolename=='项目工程值班员'" style="margin-top: 5px"><a-button type="primary" @click="shift()">交接班</a-button></div>
      </a-col>
    </a-row>
    <a-modal v-model="shiftVisible" title="交接班" class="mymodal" @cancel="cancelShift" :dialogStyle="dialogStyle">
      <template slot="footer">
        <a-button @click="cancelShift">取消</a-button>
        <a-button @click="handleOk" :disabled="isDisabled">提交</a-button>
      </template>
      <a-form-model ref="modalForm" :model="queryParams" :rules="formRules" layout="inline" class="query-form" style="margin-top: -25px">
        <a-form-model-item label="交/接班">
          <a-select v-model="queryParams.shiftstatus" style="width: 80px" :disabled="true">
            <a-select-option value="0">交班</a-select-option>
            <a-select-option value="1">接班</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="班次" required>
          <a-select v-model="shiftnumber" style="width: 80px" @change="shiftNumChange()">
            <a-select-option v-if="shiftnum=='three'" :value="1">早班</a-select-option>
            <a-select-option v-if="shiftnum=='three'" :value="2">中班</a-select-option>
            <a-select-option v-if="shiftnum=='three'" :value="3">晚班</a-select-option>
            <a-select-option v-if="shiftnum=='two'" :value="4">白班</a-select-option>
            <a-select-option v-if="shiftnum=='two'" :value="5">夜班</a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>
      <a-divider style="font-weight: bold;margin-bottom: 30px;margin-top: 3px">器材交接</a-divider>
      <div style="padding: 20px 10px 10px 25px;border-style:solid solid solid solid;border-color:skyblue; border-width: 3px;margin-top: -28px">
        <a-row>
          <a-col :span="12">器材清单</a-col>
          <a-col :span="12">器材确认情况</a-col>
        </a-row>
        <a-row v-if="userInfo.rolename=='项目调度员'">
          <a-col :span="12"><textarea style="width: 200px;" cols="26" rows="2" v-model="equiplist" :disabled="true"></textarea></a-col>
          <a-col :span="12"><textarea style="width: 200px;" cols="26" rows="2" v-model="queryParams.equipconfirm"></textarea></a-col>
        </a-row>
        <a-row v-if="userInfo.rolename=='项目工程值班员'">
          <a-col :span="12"><textarea style="width: 200px;" cols="26" rows="2" v-model="engineerEquiplist" :disabled="true"></textarea></a-col>
          <a-col :span="12"><textarea style="width: 200px;" cols="26" rows="2" v-model="queryParams.equipconfirm"></textarea></a-col>
        </a-row>
      </div>
      <a-divider style="margin-top: 25px;font-weight: bold;margin-bottom: 30px;margin-top: 3px" v-if="userInfo.rolename=='项目调度员'">消防主机状况</a-divider>
      <div style="padding: 20px 10px 10px 25px;border-style:solid solid solid solid;border-color:skyblue; border-width: 3px;margin-top: -28px" v-if="userInfo.rolename=='项目调度员'">
        <a-row>
          <a-col :span="8">主备用电源</a-col>
          <a-col :span="8">联动运行状态</a-col>
          <a-col :span="8">设备运行状态</a-col>
        </a-row>
        <a-row>
          <a-col :span="8"><a-select style="width: 120px" v-model="queryParams.power">
            <a-select-option :value="0">主电源</a-select-option>
            <a-select-option :value="1">备用电源</a-select-option>
            <a-select-option :value="2">备电故障</a-select-option>
            <a-select-option :value="3">电源故障</a-select-option>
          </a-select></a-col>
          <a-col :span="8"><a-select style="width: 120px" v-model="queryParams.linkagestatus">
            <a-select-option :value="0">手动</a-select-option>
            <a-select-option :value="1">自动</a-select-option>
          </a-select></a-col>
          <a-col :span="8"><a-select style="width: 120px" v-model="queryParams.devicestatus" :disabled="true">
            <a-select-option :value="0">正常</a-select-option>
            <a-select-option :value="1">不正常</a-select-option>
          </a-select></a-col>
        </a-row>
        <a-row style="margin-top: 10px">
          <a-col :span="24">跟进及处理情况</a-col>
        </a-row>
        <a-row>
          <a-col :span="16"><textarea style="width: 265px;" cols="38" rows="3" v-model="queryParams.description"></textarea></a-col>
          <a-col :span="8">
            <div style="border-style:solid solid solid solid;border-color:black; border-width: 1px;width: 120px">
              <a-form-model layout="inline" style="margin: -12px -10px 16px 5px;">
                <a-form-model-item label="火警" style="height: 15px"><a-input style="width: 60px;height: 20px" v-model="queryParams.firenum"></a-input></a-form-model-item>
                <a-form-model-item label="故障" style="height: 15px"><a-input style="width: 60px;height: 20px" v-model="queryParams.faultnum"></a-input></a-form-model-item>
                <a-form-model-item label="隔离" style="height: 15px"><a-input style="width: 60px;height: 20px" v-model="queryParams.insulatenum"></a-input></a-form-model-item>
              </a-form-model>
            </div>
          </a-col>
        </a-row>
      </div>
      <a-divider style="margin-top: 25px;font-weight: bold;margin-bottom: 30px;margin-top: 3px" v-if="userInfo.rolename=='项目工程值班员'">设备系统状况</a-divider>
      <div style="padding: 20px 10px 10px 25px;border-style:solid solid solid solid;border-color:skyblue; border-width: 3px;margin-top: -28px" v-if="userInfo.rolename=='项目工程值班员'">
        <a-row>
          <a-col :span="24">设备系统状况</a-col>
        </a-row>
        <a-row>
          <a-col :span="24"><textarea style="width: 412px;" cols="60" rows="3" v-model="queryParams.devicesituation"></textarea></a-col>
        </a-row>
        <a-row style="margin-top: 10px">
          <a-col :span="24">跟进及处理情况</a-col>
        </a-row>
        <a-row>
          <a-col :span="24"><textarea style="width: 412px;" cols="60" rows="3" v-model="queryParams.description"></textarea></a-col>
        </a-row>
      </div>
      <a-divider style="font-weight: bold;margin-top: 3px">交班说明</a-divider>
      <div style="border-style:solid solid solid solid;border-color:skyblue; border-width: 3px;margin-top: -12px;">
        <div style="padding: 10px 10px 10px 25px;">
          <a-row>
            <a-col :span="24">交班说明</a-col>
          </a-row>
          <a-row>
            <a-col :span="24"><textarea style="width: 412px;" cols="60" rows="2" v-model="queryParams.shiftdesc"></textarea></a-col>
          </a-row>
        </div>
      </div>
      <a-divider style="font-weight: bold;margin-top: 3px">交接班状态</a-divider>
      <div style="border-style:solid solid solid solid;border-color:skyblue; border-width: 3px;margin-top: -12px;">
        <div style="padding: 10px 10px 10px 25px;">
          <a-row>
            <a-col :span="2">状态</a-col>
            <a-col :span="2">班次</a-col>
            <a-col :span="8">时间</a-col>
            <a-col :span="12">姓名</a-col>
            <!--            <a-col :span="5">账号</a-col>-->
          </a-row>
          <a-row>
            <a-col :span="2"><span>{{'交班'}}</span></a-col>
            <a-col :span="2"><span style="color: red;font-weight: bold">{{queryParams.shiftnum?patrolShifts[queryParams.shiftnum-1].value:''}}</span></a-col>
            <a-col :span="8"><span>{{moment(queryParams.shifttime, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss')}}</span></a-col>
            <a-col :span="12"><span>{{queryParams.shiftman}}</span></a-col>
            <!--            <a-col :span="5"><span>{{queryParams.shiftaccount}}</span></a-col>-->
          </a-row>
          <a-row>
            <a-col v-if="queryParams.shiftstatus=='1'" :span="2"><span>{{'接班'}}</span></a-col>
            <a-col v-if="queryParams.shiftstatus=='1'" :span="2"><span style="color: red;font-weight: bold">{{shiftnumber?patrolShifts[shiftnumber-1].value:''}}</span></a-col>
            <a-col v-if="queryParams.shiftstatus=='1'" :span="8"><span>{{moment(queryParams.takeovertime, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss')}}</span></a-col>
            <a-col v-if="queryParams.shiftstatus=='1'" :span="12"><span>{{queryParams.takeoverman}}</span></a-col>
            <!--            <a-col v-if="queryParams.shiftstatus=='1'" :span="5"><span>{{queryParams.takeoveraccount}}</span></a-col>-->
          </a-row>
        </div>
      </div>
    </a-modal>
<!--    <add-or-edit-modal :visible.sync="modalVisible" :show-type="modalShowType" :detailData="modalDetailData" @get-operation-result="getUnhandleIotEventNum(true)"></add-or-edit-modal>-->
    <monitor-point-list :visible.sync="monitorModalVisible" :show-type="monitorModalShowType"></monitor-point-list>
    <event-record-list :visible.sync="eventModalVisible" :show-type="eventModalShowType"></event-record-list>
    <network-log-list :visible.sync="networkModalVisible" :show-type="networkModalShowType"></network-log-list>
  </div>
</template>
<script>
import {getCache, removeCache} from 'U'
import {
  getIotMaindata,
  getMonitorPointNameList,
  getNetworkLogStatistics,
  getUnhandleEventrecord,
} from "A/monitoring";
import {mapState} from "vuex";
import moment from "moment";
import {patrolShifts} from "@/json/wlwhistory";
import {
  addPatrolRecordData,
  addShiftData,
  getEquipmentListByCondition, getPatrolSchemeTemplate,
  getShiftData, resetPatrolRecord,
  addEngineerPatrolRecordData,
  addEngineerShiftData,
  getEngineerEquipmentListByCondition, getEngineerPatrolSchemeTemplate,
  getEngineerShiftData, resetEngineerPatrolRecord,
} from "A/patrol";
import {logout} from "A/login";
import MonitorPointList from "C/modals/MonitorPointList";
import EventRecordList from "C/modals/EventRecordList";
import NetworkLogList from "C/modals/NetworkLogList";
export default{
  name:"MiddleTop",
  components:{
    // addOrEditModal,
    MonitorPointList,
    EventRecordList,
    NetworkLogList,
  },
  data() {
    return {
      moment,
      patrolShifts,
      systemNames:[],
      alarmTypes:[],
      systemNamesMap:{},
      monitorModalVisible:false,
      monitorModalShowType:'',
      eventModalVisible:false,
      eventModalShowType:'',
      networkModalVisible:false,
      networkModalShowType:'',
      dialogStyle:{
        top:"10px"
      },
      shiftnum:'',
      shiftnumber:null,
      config:{
        data:[],
        oddRowBGC:'white',
        evenRowBGC:'white',
        rowNum:2,
        align:['left','left','left','left'],
        columnWidth: [100,160,60,100,1]
      },
      configVisible:false,
      alarmData:[],
      netMonitor:"上月掉线0次,最长0分钟",
      userdepnum:'',
      clientnum:'',
      selectedArea:'',
      selectedPlace:'',
      selectedLiftyear:'',
      selectedDevicemodelid:'',
      placeOptions:[],
      terminaltypes:[],
      queryAreaSelected: [],
      areaOptions: [],
      areaCascaderSelected: [],
      userdepidCascaderSelected: [],
      mainData:{
        monitorpointnum:0,
        clientnum:0,
        offclientnum:0,
        devclientnum:0,
        offdevclientnum:0,
      },
      unhandleData:{
        unhandlealarm:0,
        unhandleevent:0,
      },
      monitorpointNameList: [],
      userdepid:'',
      shiftVisible:false,
      isShift:true,
      isCommonShift:true,
      equiplist:'',
      engineerEquiplist:'',
      queryParams:{
        monitorpointnum:'',
        shiftnum:null,
        takeovernum:null,
        equipconfirm:'齐备并完好',
        power:0,
        linkagestatus:0,
        devicestatus:0,
        devicesituation:"无",
        shifttime:'',
        takeovertime:'',
        shiftstatus:"",
        description:'无',
        shiftman:'',
        shiftaccount:'',
        takeoverman:'',
        takeoveraccount:'',
        shiftdesc:'无',
        firenum:0,
        faultnum:0,
        insulatenum:0,
      },
      isTakeover:false,
      formRules:{
        shiftnum: [{required: true, message: '请选择班次'}],
      },
      alarmUrl:require('../../../../assets/image/alarm.png'),
      flag:false,
      timer:null,
      alarmNum:0,
      patrolData:[],
      isDisabled:false,

      writeAlarmData:{
        monitorpointnum:'',
        eventnum:'',
        systemnameid:'',
        eventcode:'',
        eventchildcode:'',
        eventstarttime:'',
        writetime:'',
        timerange:'',
        eventrecordid:'',
        biginfluence:1,
        repairorder:"无",
        plannedwork:0,
        confirmedevent:false,
      },
      modalVisible: false,
      modalShowType: 'record',
      modalDetailData: null,
      timers:null,
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
  },
  watch:{
    'monitorpointname':function (){
      this.$emit('select-opt',"monitorpointname",this.monitorpointname)
    },
    'queryParams.firenum':function (){
      if(this.queryParams.firenum==0&&this.queryParams.faultnum==0&&this.queryParams.insulatenum==0){
        this.queryParams.devicestatus=0
      }else{
        this.queryParams.devicestatus=1
      }
    },
    'queryParams.faultnum':function (){
      if(this.queryParams.firenum==0&&this.queryParams.faultnum==0&&this.queryParams.insulatenum==0){
        this.queryParams.devicestatus=0
      }else{
        this.queryParams.devicestatus=1
      }
    },
    'queryParams.insulatenum':function (){
      if(this.queryParams.firenum==0&&this.queryParams.faultnum==0&&this.queryParams.insulatenum==0){
        this.queryParams.devicestatus=0
      }else{
        this.queryParams.devicestatus=1
      }
    },
  },
  mounted(){
    let that = this;
    this.timers = setInterval(()=>{
      that.getIotMain()
      // that.getUnhandleEventNum()
    },5*60*1000)
  },
  beforeDestroy(){
    clearInterval(this.timers)
    this.timers=null
  },
  created () {
    let logininfo = getCache('logininfo', true);
    if(logininfo) {
      this.userdepid = logininfo.userdepid;
    }
    let dictionary = getCache('dictionary', true);
    if(dictionary) {
      this.bigInfluences = dictionary.bigInfluences;
      this.alarmTypes = dictionary.alarmTypes;
      this.alarmTypesMap = dictionary.alarmTypesMap;
      this.alarmChildTypes = dictionary.alarmChildTypes;
      this.systemNames = dictionary.systemNames;
      this.systemNamesMap = dictionary.systemNamesMap;
      this.feedbackList = dictionary.feedbackList;
      this.abnormalTypes = dictionary.abnormalTypes;
      this.abnormalTypesMap = dictionary.abnormalTypesMap;
      this.equipmentTypes = dictionary.equipmentTypes;
      // this.manageTypes = dictionary.manageTypes;
      this.recordTypes = dictionary.recordTypes;
    }
    this.getIotMain()
    this.getMonitorpointName()
    this.getUnhandleEventNum()
    this.getEquipments()
    this.getEngineerEquipments()
    this.getNetWorkData()
    if(this.$store.state.shiftFlag){
      this.shift()
      this.$store.state.shiftFlag=false;
    }
  },
  methods: {
    //统计数据
    getIotMain(){//物联网大数据主视图
      let params={
        areacode: this.selectedArea=="0"?"":this.selectedArea,
        userdepsid:this.queryParams.userdepid?this.queryParams.userdepid:this.userdepid,
      }
      getIotMaindata(params).then(res=>{
        if(res.returncode==="0"){
          this.mainData = res.item
        }
      })
    },
    //监控端数量弹窗
    gotoMonitorPage(type){
      if(type=='monitorinfo'){
        this.monitorModalShowType='monitorinfo'
        this.monitorModalVisible=true
      }else if(type=='offline'){
        this.monitorModalShowType='offline'
        this.monitorModalVisible=true
      }
    },
    //报警异常数量弹窗
    gotoEventPage(type){
      if(type=='alarm'){
        this.eventModalShowType='unhandleAlarm'
        this.eventModalVisible=true
      }else if(type=='abnormal'){
        this.eventModalShowType='unhandleAbnormal'
        this.eventModalVisible=true
      }
    },
    //未处理报警异常数量
    getUnhandleEventNum(){
      let params={
      }
      getUnhandleEventrecord(params).then(res=>{
        if(res&&res.returncode==="0"){
          this.unhandleData=res.item[0]
        }
      })
    },

    //网络监测数据
    getNetWorkData(){
      let params={
        lastmonth:true,
      }
      getNetworkLogStatistics(params).then(res=>{
        if(res&&res.returncode=='0'){
          this.netMonitor="上月掉线"+res.item[0].total+"次,"+"最长"+(res.item[0].total==0?0:(this.timeHandle(res.item[0].maxofflinetime)))+"分钟"
        }
      })
    },
    timeHandle(time){
      let returnTime;
      let day;
      let hour;
      let minutes;
      let seconds;
      if(time>86400) {
        day = Math.floor(time / 86400);
        returnTime=day + "天";
        if (time % 86400 > 3600) {
          hour = Math.floor(time % 86400 / 3600);
          returnTime+=hour + "时";
          if (time % 86400 % 3600 > 60) {
            minutes = Math.floor(time % 86400 % 3600 / 60);
            returnTime+=minutes + "分";
            seconds = Math.floor(time % 86400 % 3600 % 60);
            returnTime+=seconds + "秒";
          } else {
            seconds = Math.floor(time % 86400 % 3600 % 60);
            returnTime+=seconds + "秒";
          }
        } else {
          if (time % 86400 > 60) {
            minutes = Math.floor(time % 86400 / 60);
            returnTime+=minutes + "分";
            seconds = Math.floor(time % 86400 % 60);
            returnTime+=seconds + "秒";
          } else {
            seconds = Math.floor(time % 86400);
            returnTime+=seconds + "秒";
          }
        }
      }else if(time>=3600&&time<86400){
        let hour = Math.floor(time % 86400 / 3600);
        returnTime=hour + "时";
        if (time % 3600 > 60) {
          minutes = Math.floor(time % 3600 / 60);
          returnTime+=minutes + "分";
          seconds = Math.floor(time % 3600 % 60);
          returnTime+=seconds + "秒";
        } else {
          seconds = Math.floor(time % 3600 % 60);
          returnTime+=seconds + "秒";
        }
      }else if(time>=60&&time<3600){
        minutes = Math.floor(time / 60);
        returnTime=minutes + "分";
        seconds = Math.floor(time % 60);
        returnTime+=seconds + "秒";
      }else{
        returnTime=time + "秒";
      }
      return returnTime
    },
    //网络监测详情
    networkDetail(){
      this.networkModalShowType='networkLog'
      this.networkModalVisible=true
    },


    //交接班弹窗
    shift(){
      if(this.userInfo.rolename==="项目工程值班员"){
        this.getEngineerShiftData()
      }else{
        this.getShiftData()
      }
      this.shiftVisible=true
    },
    //交接班数据
    getMonitorpointName(){
      getMonitorPointNameList({userdepid:this.userInfo.userdepid}).then(res=>{
        if(res&&res.returncode=='0'){
          this.queryParams.monitorpointnum=res.item[0].monitorpointnum
          if(this.userInfo.rolename==="项目工程值班员"){
            this.getEngineerShiftData()
          }else{
            this.getShiftData()
          }
        }
      })
    },
    //项目调度员交接班
    getShiftData(){
      if(this.queryParams.monitorpointnum){
        let params={
          monitorpointnum:this.queryParams.monitorpointnum,
        }
        getShiftData(params).then(res=>{
          if(res&&res.returncode=='0') {
            if (res.hasOwnProperty("item")) {
              this.queryParams = res.item
              if (res.item.shiftstatus == "0") {//交班
                if (res.item.shiftnum == 5 || res.item.shiftnum == 4) {
                  this.shiftnum = 'two'
                  this.shiftnumber = res.item.shiftnum
                } else if (res.item.shiftnum == 1 || res.item.shiftnum == 2 || res.item.shiftnum == 3) {
                  this.shiftnumber = res.item.shiftnum
                  this.shiftnum = 'three'
                }
                this.queryParams.shiftaccount = this.userInfo.useraccount
                this.queryParams.shiftman = this.userInfo.username
                this.queryParams.shifttime = moment(new Date()).format("YYYYMMDDHHmmss")
                this.queryParams.takeoveraccount = ''
                this.queryParams.takeoverman = ''
                this.queryParams.takeovertime = null

                this.isTakeover = res.patrol.isTakeover
                this.isCommonShift = res.patrol.isCommonShift
              } else {//接班
                this.isCommonShift = res.patrol.isCommonShift
                this.isTakeover = res.patrol.isTakeover
                this.shiftnumber = res.patrol.shiftnumber
                this.shiftnum = res.patrol.shiftnum
                this.queryParams.takeovernum = res.patrol.shiftnumber

                this.queryParams.takeoveraccount = this.userInfo.useraccount
                this.queryParams.takeoverman = this.userInfo.username
                this.queryParams.takeovertime = moment(new Date()).format("YYYYMMDDHHmmss")
              }
              this.patrolData = res.patrol.patrolData
              this.$store.state.patrolschemeid = res.patrol.patrolschemeid
              this.$store.state.patrolrecordid = res.item.patrolrecordid != 0 && res.item.patrolrecordid != null ? res.item.patrolrecordid : res.patrol.patrolData[0].patrolrecordid
            } else {
              this.getEquipments()
              this.isCommonShift = res.patrol.isCommonShift
              this.queryParams.shiftaccount = this.userInfo.useraccount
              this.queryParams.shiftman = this.userInfo.username
              this.queryParams.shifttime = moment(new Date()).format("YYYYMMDDHHmmss")
              this.queryParams.takeoveraccount = ''
              this.queryParams.takeoverman = ''
              this.queryParams.takeovertime = null
              this.getPatrolSchemeTemplate(this.queryParams.monitorpointnum)
              this.patrolData = res.patrol.patrolData
              this.$store.state.patrolschemeid = res.patrol.patrolschemeid
              this.$store.state.patrolrecordid = res.patrol.patrolData[0].patrolrecordid
            }
          }
        })
      }else{
        this.getMonitorpointName()
      }
    },
    getPatrolSchemeTemplate(monitorpointnum,patrolrecordid){
      this.columns=[
        {
          title: '序号',
          dataIndex: 'keynum',
          align: 'center',
        },
        {
          title: '管理类型',
          colSpan: 1,
          dataIndex: 'managetype',
          align: 'center',
          width: 180
        },
        {
          title: '巡查线路编号',
          dataIndex: 'linenumber',
          align: 'center',
          width: 100,
          scopedSlots: { customRender: 'linenumber' },
        },
      ]
      getPatrolSchemeTemplate({monitorpointnum:monitorpointnum,patrolrecordid: patrolrecordid,finishsign:0}).then(res=>{
        if(res&&res.returncode=='0') {
          this.queryParams.shiftstatus=res.status.toString()
          if (res.patrolshift == 5 || res.patrolshift == 4) {
            this.shiftnum = 'two'
            this.shiftnumber = res.item.shiftnum
          } else if (res.patrolshift == 1 || res.patrolshift == 2 || res.patrolshift == 3) {
            this.shiftnumber = res.item.shiftnum
            this.shiftnum = 'three'
          }
          this.queryParams.shiftnum=res.patrolshift
          this.shiftnumber=res.patrolshift

        }else{
          this.logout()
        }
      })
    },

    //项目工程值班员交接班
    getEngineerShiftData(){
      if(this.queryParams.monitorpointnum){
        let params={
          monitorpointnum:this.queryParams.monitorpointnum,
        }
        getEngineerShiftData(params).then(res=>{
          if(res&&res.returncode=='0') {
            if (res.hasOwnProperty("item")) {
              this.queryParams = res.item
              if (res.item.shiftstatus == "0") {//交班
                if (res.item.shiftnum == 5 || res.item.shiftnum == 4) {
                  this.shiftnum = 'two'
                  this.shiftnumber = res.item.shiftnum
                } else if (res.item.shiftnum == 1 || res.item.shiftnum == 2 || res.item.shiftnum == 3) {
                  this.shiftnumber = res.item.shiftnum
                  this.shiftnum = 'three'
                }
                this.queryParams.shiftaccount = this.userInfo.useraccount
                this.queryParams.shiftman = this.userInfo.username
                this.queryParams.shifttime = moment(new Date()).format("YYYYMMDDHHmmss")
                this.queryParams.takeoveraccount = ''
                this.queryParams.takeoverman = ''
                this.queryParams.takeovertime = null

                this.isTakeover = res.patrol.isTakeover
                this.isCommonShift = res.patrol.isCommonShift
              } else {//接班
                this.isCommonShift = res.patrol.isCommonShift
                this.isTakeover = res.patrol.isTakeover
                this.shiftnumber = res.patrol.shiftnumber
                this.shiftnum = res.patrol.shiftnum
                this.queryParams.takeovernum = res.patrol.shiftnumber

                this.queryParams.takeoveraccount = this.userInfo.useraccount
                this.queryParams.takeoverman = this.userInfo.username
                this.queryParams.takeovertime = moment(new Date()).format("YYYYMMDDHHmmss")
              }
              this.patrolData = res.patrol.patrolData
              this.$store.state.patrolschemeid = res.patrol.patrolschemeid
              this.$store.state.patrolrecordid = res.item.patrolrecordid != 0 && res.item.patrolrecordid != null ? res.item.patrolrecordid : res.patrol.patrolData[0].patrolrecordid
            } else {
              this.getEngineerEquipments()
              this.isCommonShift = res.patrol.isCommonShift
              this.queryParams.shiftaccount = this.userInfo.useraccount
              this.queryParams.shiftman = this.userInfo.username
              this.queryParams.shifttime = moment(new Date()).format("YYYYMMDDHHmmss")
              this.queryParams.takeoveraccount = ''
              this.queryParams.takeoverman = ''
              this.queryParams.takeovertime = null
              this.getEngineerPatrolSchemeTemplate(this.queryParams.monitorpointnum)
              this.patrolData = res.patrol.patrolData
              this.$store.state.patrolschemeid = res.patrol.patrolschemeid
              this.$store.state.patrolrecordid = res.patrol.patrolData[0].patrolrecordid
            }
          }
        })
      }else{
        this.getMonitorpointName()
      }
    },
    getEngineerPatrolSchemeTemplate(monitorpointnum,patrolrecordid){
      this.columns=[
        {
          title: '序号',
          dataIndex: 'keynum',
          align: 'center',
        },
        {
          title: '管理类型',
          colSpan: 1,
          dataIndex: 'managetype',
          align: 'center',
          width: 180
        },
        {
          title: '巡查线路编号',
          dataIndex: 'linenumber',
          align: 'center',
          width: 100,
          scopedSlots: { customRender: 'linenumber' },
        },
      ]
      getEngineerPatrolSchemeTemplate({monitorpointnum:monitorpointnum,patrolrecordid: patrolrecordid,finishsign:0}).then(res=>{
        if(res&&res.returncode=='0') {
          this.queryParams.shiftstatus=res.status.toString()
          if (res.patrolshift == 5 || res.patrolshift == 4) {
            this.shiftnum = 'two'
            this.shiftnumber = res.item.shiftnum
          } else if (res.patrolshift == 1 || res.patrolshift == 2 || res.patrolshift == 3) {
            this.shiftnumber = res.item.shiftnum
            this.shiftnum = 'three'
          }
          this.queryParams.shiftnum=res.patrolshift
          this.shiftnumber=res.patrolshift

        }else{
          this.logout()
        }
      })
    },

    //器材清单
    getEquipments(){
      getEquipmentListByCondition({monitorpointnum:this.queryParams.monitorpointnum}).then(res=>{
        if(res&&res.returncode=='0'){
          this.equiplist=""
          for(let i=0;i<res.item.length;i++){
            if(i==res.item.length-1){
              this.equiplist+=res.item[i].equipmentname+'。'
            }else{
              this.equiplist+=res.item[i].equipmentname+'，'
            }
          }
        }
      })
    },

    //器材清单
    getEngineerEquipments(){
      getEngineerEquipmentListByCondition({monitorpointnum:this.queryParams.monitorpointnum}).then(res=>{
        if(res&&res.returncode=='0'){
          this.engineerEquiplist=""
          for(let i=0;i<res.item.length;i++){
            if(i==res.item.length-1){
              this.engineerEquiplist+=res.item[i].equipmentname+'。'
            }else{
              this.engineerEquiplist+=res.item[i].equipmentname+'，'
            }
          }
        }
      })
    },

    //班次切换
    shiftNumChange(){
      if(this.queryParams.shiftstatus=='0'){
        this.queryParams.shiftnum=this.shiftnumber
      }else if(this.queryParams.shiftstatus=='1'){
        this.queryParams.takeovernum=this.shiftnumber
      }
    },
    //启用提交按钮
    enable(){
      setTimeout(()=>{
        this.isDisabled=false
      },2000)
    },
    //提交交接班
    handleOk(e) {
      this.$refs.modalForm.validate(valid => {
        if (valid && this.queryParams.shiftnum) {
          this.isDisabled=true
          this.enable()
          if (this.queryParams.shiftstatus === "1") {
            if (this.isTakeover !== false) {
              this.queryParams.shifttime = null
              this.queryParams.takeovertime = moment(new Date()).format("YYYYMMDDHHmmss")
            } else {
              this.$message.error("您已接班，请勿多次接班")
              return
            }
          } else {
            this.queryParams.takeoveraccount = ''
            this.queryParams.takeoverman = ''
            this.queryParams.takeovertime = null
            this.queryParams.shifttime = moment(new Date()).format("YYYYMMDDHHmmss")
          }
          let params = {
            monitorpointnum: this.queryParams.monitorpointnum,
            patrolschemeid: '',
            patrolrecordid: this.$store.state.patrolrecordid,
            addoredit: 'edit',
            finishsign: this.isCommonShift == true ? 1 : 0,
            data: this.patrolData,
          }
          if (this.queryParams.shiftstatus == "0") {//交班
            if(this.userInfo.rolename==="项目工程值班员"){
              addEngineerPatrolRecordData(params).then(res => {
                if (res && res.returncode == '0') {
                  let params = {
                    ...this.queryParams,
                    equiplist: this.engineerEquiplist,
                    patrolrecordid: this.$store.state.patrolrecordid,
                  };
                  if (this.queryParams.shiftnum == 0) {
                    this.$message.error("请检查班次是否正确填写，如再次报此异常，请手动选择班次")
                  } else {
                    addEngineerShiftData(params).then(res => {
                      this.hideLoading();
                      if (res && res.returncode == '0') {
                        this.$message.success('交班成功，平台将在稍后退出');
                        this.shiftVisible = false;
                        let that = this;
                        setTimeout(() => {
                          logout().then(res => {
                            // if(res && res.returncode == '0') {
                            removeCache('logininfo', true);
                            that.$store.commit('main/resetTabs');
                            that.$store.commit('setToken');
                            that.$router.push({
                              name: 'login',
                              params: {
                                flag: true
                              }
                            })
                          }).catch(err => {
                            removeCache('logininfo', true);
                            that.$store.commit('main/resetTabs');
                            that.$store.commit('setToken');
                            that.$router.push({
                              name: 'login',
                              params: {
                                flag: true
                              }
                            })
                          })
                        }, 3000)
                      } else {
                        this.$message.error(res.errormsg || '操作失败');
                      }
                    })
                  }
                } else {
                  this.$message.error("无法完成交班，请先完成巡查记录")
                }
              }).catch(err => {
                this.$message.error("无法完成交班，请先完成巡查记录")
              })
            }else{
              addPatrolRecordData(params).then(res => {
                if (res && res.returncode == '0') {
                  let params = {
                    ...this.queryParams,
                    equiplist: this.equiplist,
                    patrolrecordid: this.$store.state.patrolrecordid,
                  };
                  if (this.queryParams.shiftnum == 0) {
                    this.$message.error("请检查班次是否正确填写，如再次报此异常，请手动选择班次")
                  } else {
                    addShiftData(params).then(res => {
                      this.hideLoading();
                      if (res && res.returncode == '0') {
                        this.$message.success('交班成功，平台将在稍后退出');
                        this.shiftVisible = false;
                        let that = this;
                        setTimeout(() => {
                          logout().then(res => {
                            // if(res && res.returncode == '0') {
                            removeCache('logininfo', true);
                            that.$store.commit('main/resetTabs');
                            that.$store.commit('setToken');
                            that.$router.push({
                              name: 'login',
                              params: {
                                flag: true
                              }
                            })
                          }).catch(err => {
                            removeCache('logininfo', true);
                            that.$store.commit('main/resetTabs');
                            that.$store.commit('setToken');
                            that.$router.push({
                              name: 'login',
                              params: {
                                flag: true
                              }
                            })
                          })
                        }, 3000)
                      } else {
                        this.$message.error(res.errormsg || '操作失败');
                      }
                    })
                  }
                } else {
                  this.$message.error("无法完成交班，请先完成巡查记录")
                }
              }).catch(err => {
                this.$message.error("无法完成交班，请先完成巡查记录")
              })
            }
          } else {//接班
            if(this.userInfo.rolename==="项目工程值班员"){
              let params = {
                ...this.queryParams,
                equiplist: this.engineerEquiplist,
              };
              if (this.queryParams.takeovernum == 0) {
                this.$message.error("请检查班次是否正确填写，如再次报此异常，请手动选择班次")
              } else {
                addEngineerShiftData(params).then(res => {
                  this.hideLoading();
                  if (res && res.returncode == '0') {
                    this.$message.success('接班成功');
                    this.shiftVisible = false;
                  }
                })
              }
            }else{
              let params = {
                ...this.queryParams,
                equiplist: this.equiplist,
              };
              if (this.queryParams.takeovernum == 0) {
                this.$message.error("请检查班次是否正确填写，如再次报此异常，请手动选择班次")
              } else {
                addShiftData(params).then(res => {
                  this.hideLoading();
                  if (res && res.returncode == '0') {
                    this.$message.success('接班成功');
                    this.shiftVisible = false;
                  }
                })
              }
            }
          }
        } else {
          this.$message.error("请选择班次")
        }
      })
    },
    logout(){
      let that = this
      that.shiftVisible=false
      if(this.userInfo.rolename==='项目调度员'||this.userInfo.rolename==="项目工程值班员") {
        this.$warning({
          title: '提示',
          content: '巡查方案和巡查模板异常，现退出平台',
          onOk() {
            if(this.userInfo.rolename==="项目工程值班员"){
              resetEngineerPatrolRecord({monitorpointnum:that.queryParams.monitorpointnum,finishsign: 1})
            }else{
              resetPatrolRecord({monitorpointnum:that.queryParams.monitorpointnum,finishsign: 1})
            }
            logout().then(res => {
              // if(res && res.returncode == '0') {
              removeCache('logininfo', true);
              that.$store.commit('main/resetTabs');
              that.$store.commit('setToken');
              that.$router.push({
                name: 'login',
                params: {
                  flag: true
                }
              })
            }).catch(err => {
              removeCache('logininfo', true);
              that.$store.commit('main/resetTabs');
              that.$store.commit('setToken');
              that.$router.push({
                name: 'login',
                params: {
                  flag: true
                }
              })
            })
          },
        });
      }else{
        this.$warning({
          title: '提示',
          content: '当前未设置巡查方案或巡查模板，请尽快添加巡查方案和巡查模板',
        })
      }
    },
    //取消交接班
    cancelShift(){
      if(this.queryParams.shiftstatus=="0"){
        let that = this
        that.shiftVisible=false
        this.$confirm('您确定要取消交班吗？如果是请点击确认按钮，如果不是，请点击取消按钮。',{
          type:'warning',
          centered:true,
        }).then(()=>{
          that.shiftVisible=false
        }).catch(()=>{
          that.shiftVisible=true
        });
      }else if(this.queryParams.shiftstatus=="1"){
        let that = this
        that.shiftVisible=false
        this.$confirm('您确定要取消接班吗？如果是请点击确认按钮，如果不是，请点击取消按钮。',{
          type:'warning',
          centered:true,
        }).then(()=>{
          that.shiftVisible=false
        }).catch(()=>{
          that.shiftVisible=true
        });
      }else{
        this.shiftVisible=false
      }
    },
  },
}
</script>
<style lang="scss" scoped>
  .middle-top{
    width: 145%;
    height: 70%;
  }
  .middle-top-title{
    color: white;
    font-size: 18px;
    font-weight: 700;
    & >span{
      font-family: ds-digitalnormal;
      font-size: 22px;
    }
  }
  .flex{
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #9adeff;
    font-size: 16px;
    pointer-events: auto;
    margin-top: -20px;
  }
  .tab-size{
    pointer-events: auto;
  }
  .tab-size span{
    display: inline-block;
    background-color: rgba(1,37,77,.7);
    color: #fff;
    cursor: pointer;
    font-size: 16px;
    line-height: 16px;
    padding: 5px 14px;
    &.active{
      background-color: #0060d5;
    }
  }
  .word{
    position: absolute;
    left: 50%;
    top: 55%;
    transform: translate(-50%, -50%);
  }
</style>
<style lang="scss">
.mymodal{
  .ant-modal-header{
    background-color: #40a9ff;
  }
  .ant-modal-title{
    color: white;
    font-weight: bold;
    font-size: 20px;
  }
  .ant-modal-footer{
    background-color: #40a9ff;
  }
}
  .self-select{
    .ant-select{
      width:120px;
      color: #90a5b6;
    }
    .anticon svg{
      color: #90a5b6;
    }
    .ant-select-selection,.ant-select-selection__clear,.ant-cascader-picker{
      background-color: transparent;
    }
    .ant-form-item-label > label,.ant-cascader-picker-label{
      color: #90a5b6!important;
    }
  }
  .self.ant-select-dropdown{
    background-color: transparent;
    .ant-select-dropdown-menu-item{
      color: #90a5b6;
    }
  }
  .self.ant-cascader-menus{
    background-color: transparent;
    color: #90a5b6;
  }
</style>