<template>
  <a-modal v-model="modalVisible" :title="modalTitle" :footer="false" :width="1280" :centered="true">
    <div class="menu-modal-container">
      <div v-if="this.showType != 'monitorpoint'">
        <a-tabs default-active-key="1" @change="getMonitorOrDevice">
          <a-tab-pane key="1" tab="监控软终端">
            <a-table :columns="monitorinfoColumns" :data-source="tableData" :rowKey="(record,index)=> index" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" :pagination="false">
              <span slot="isonline" slot-scope="value">
                <a-tag :color="value=='1'?'green':'red'">{{value=='1'?'在线':'离线'}}</a-tag>
              </span>
            </a-table>
            <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" @change="pageChange" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
              <template slot="buildOptionText" slot-scope="props">
                <span>{{ props.value }}条/页</span>
              </template>
            </a-pagination>
          </a-tab-pane>
          <a-tab-pane key="2" tab="监控硬终端">
            <a-table :columns="deviceinfoColumns" :data-source="tableData_Dev" :rowKey="(record,index)=> index" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" :pagination="false">
              <span slot="isonline" slot-scope="value">
                <a-tag :color="value=='1'?'green':'red'">{{value=='1'?'在线':'离线'}}</a-tag>
              </span>
            </a-table>
            <a-pagination v-model="currentPage1" :total="pagination1.total" :page-size="pagination1.pageSize" @change="pageChange1" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
              <template slot="buildOptionText" slot-scope="props">
                <span>{{ props.value }}条/页</span>
              </template>
            </a-pagination>
          </a-tab-pane>
        </a-tabs>
      </div>
      <div v-if="this.showType == 'monitorpoint'">
        <a-table :columns="monitorpointColumns" :data-source="tableData" :rowKey="(record,index)=> index" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" :pagination="false">
          <span slot="isonline" slot-scope="value">
            <a-tag :color="value=='1'?'green':'red'">{{value=='1'?'在线':'离线'}}</a-tag>
          </span>
          <span slot="onlineorder" slot-scope="value">
            <a-tag :color="value==4?'green':value==0?'red':''">{{value==4?'在线':value==0?'离线':value}}</a-tag>
          </span>
        </a-table>
        <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" @change="pageChange" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </div>
  </a-modal>
</template>

<script>
import pagination from "@/mixins/pagination";
import {getMonitorinfoListByCondition, getMonitorPointListByCondition} from "A/monitoring";
import {mapGetters, mapState} from "vuex";
import {getDeviceinfoListByCondition} from "A/ai";

export default {
  name: "MonitorPointList",
  mixins: [pagination],
  props: {
    visible: {
      default: false
    },
    showType: {
      default: ''
    },
    detailData: {
      default: null
    },
  },
  components: {},
  data() {
    return {
      modalVisible: false,
      queryParams: {},
      monitorpointColumns: [
        {
          title: '项目ID',
          dataIndex: 'monitorpointnum',
          key: 'monitorpointnum',
          ellipsis: true,
          width: 165
        },
        {
          title: '项目名称',
          dataIndex: 'monitorpointname',
          key: 'monitorpointname',
          ellipsis: true,
        },
        {
          title: '地址',
          dataIndex: 'address',
          key: 'address',
          ellipsis: true,
        },
        {
          title: '使用单位',
          dataIndex: 'userdepname',
          key: 'userdepname',
          ellipsis: true,
        },
        {
          title: '在线/离线',
          dataIndex: 'onlineorder',
          key: 'onlineorder',
          ellipsis: true,
          align: "center",
          width: 80,
          scopedSlots: {customRender: 'onlineorder'}
        },
        {
          title: '',
          key: 'monitoring',
          align: 'center',
          width: 100,
          scopedSlots: {customRender: 'monitoring'}
        }],
      monitorinfoColumns: [
        {
          title: '监控端名称',
          dataIndex: 'monitorname',
          key: 'monitorname',
          ellipsis: true,
        },
        {
          title: '监控端ID',
          dataIndex: 'monitornum',
          key: 'monitornum',
          ellipsis: true,
          width: 165
        },
        {
          title: '版本号',
          dataIndex: 'version',
          key: 'version',
          ellipsis: true,
        },
        {
          title: '状态',
          dataIndex: 'isonline',
          key: 'isonline',
          ellipsis: true,
          scopedSlots: {customRender: 'isonline'},
          width: 60
        },
        {
          title: '使用项目名称',
          dataIndex: 'monitorpointname',
          key: 'monitorpointname',
          ellipsis: true,
        },
        {
          title: '项目地址',
          dataIndex: 'address',
          key: 'address',
          ellipsis: true,
        },
        {
          title: '描述',
          dataIndex: 'monitordecs',
          key: 'monitordecs',
          ellipsis: true,
        },
      ],
      deviceinfoColumns: [
        {
          title: '硬终端名称',
          dataIndex: 'devicename',
          key: 'devicename',
          ellipsis: true,
        },
        {
          title: '硬终端ID',
          dataIndex: 'devicenum',
          key: 'devicenum',
          ellipsis: true,
          width: 165
        },
        // {
        //   title: '版本号',
        //   dataIndex: 'version',
        //   key: 'version',
        //   ellipsis: true,
        // },
        {
          title: '状态',
          dataIndex: 'isonline',
          key: 'isonline',
          ellipsis: true,
          scopedSlots: {customRender: 'isonline'},
          width: 60
        },
        {
          title: '使用项目名称',
          dataIndex: 'monitorpointname',
          key: 'monitorpointname',
          ellipsis: true,
        },
        {
          title: '项目地址',
          dataIndex: 'address',
          key: 'address',
          ellipsis: true,
        },
        {
          title: '描述',
          dataIndex: 'devicedecs',
          key: 'devicedecs',
          ellipsis: true,
        },
      ],
      tableColumns: [],
      tableData: [],
      tableData_Dev:[],
      tableLoading: false,
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    ...mapGetters(['uploadHeaders', 'uploadBasemap']),
    modalTitle() {
      if (this.showType == 'monitorpoint') {
        return '项目列表'
      } else if (this.showType == 'monitorinfo') {
        return '监控端列表'
      } else if (this.showType == 'offline') {
        return '离线监控端列表'
      } else {
        return '';
      }
    }
  },
  watch: {
    visible(val) {
      if (val) {
        this.modalVisible = true;
        this.getTableData(true);
      }
    },
    modalVisible(val) {
      if (!val) {
        this.$emit('update:visible', false);
      }
    }
  },
  created() {
    this.modalVisible = this.visible;
  },
  methods: {
    getTableData(firstPage) {
      if (firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      if(this.showType=='monitorpoint'){
        let params = {
          ...this.queryParams,
          userdepsid:this.detailData,
          linkclient:1,
          pageno: this.currentPage,
          pagesize: this.pagination.pageSize
        };
        getMonitorPointListByCondition(params).then(res => {
          this.tableLoading = false;
          if (res && res.returncode == '0') {
            this.tableData = res.item;
            this.pagination.total = res.count;
          }
        }).catch(err => {
          this.tableLoading = false;
        })
      }else{
        let params = {
          ...this.queryParams,
          userdepsid:this.detailData,
          offline:this.showType=='offline'?true:false,
          pageno: this.currentPage,
          pagesize: this.pagination.pageSize
        };
        getMonitorinfoListByCondition(params).then(res => {
          this.tableLoading = false;
          if (res && res.returncode == '0') {
            this.tableData = res.item;
            this.pagination.total = res.count;
          }
        }).catch(err => {
          this.tableLoading = false;
        })
        let param = {
          ...this.queryParams,
          userdepsid:this.detailData,
          offline:this.showType=='offline'?true:false,
          pageno: this.currentPage1,
          pagesize: this.pagination1.pageSize
        };
        getDeviceinfoListByCondition(param).then(res => {
          this.tableLoading = false;
          if (res && res.returncode == '0') {
            this.tableData_Dev = res.item;
            this.pagination1.total = res.count;
          }
        }).catch(err => {
          this.tableLoading = false;
        })
      }
    },
    getMonitorOrDevice(e){
      if(e==1){
        let params = {
          ...this.queryParams,
          userdepsid:this.detailData,
          offline:this.showType=='offline'?true:false,
          pageno: this.currentPage,
          pagesize: this.pagination.pageSize
        };
        getMonitorinfoListByCondition(params).then(res => {
          this.tableLoading = false;
          if (res && res.returncode == '0') {
            this.tableData = res.item;
            this.pagination.total = res.count;
          }
        }).catch(err => {
          this.tableLoading = false;
        })
      }else if(e==2){
        let params = {
          ...this.queryParams,
          userdepsid:this.detailData,
          offline:this.showType=='offline'?true:false,
          pageno: this.currentPage1,
          pagesize: this.pagination1.pageSize
        };
        getDeviceinfoListByCondition(params).then(res => {
          this.tableLoading = false;
          if (res && res.returncode == '0') {
            this.tableData_Dev = res.item;
            this.pagination1.total = res.count;
          }
        }).catch(err => {
          this.tableLoading = false;
        })
      }
    },
  }
}
</script>

<style scoped>

</style>