<template>
  <a-modal v-model="modalVisible" :title="modalTitle" :footer="false" :width="1280" :centered="true">
    <div class="box-container">
      <div class="box-container-inner">
        <div class="simple-query">
          <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form">
<!--            <a-form-model-item label="" prop="today" style="width: 30%">-->
<!--              <a-button type="primary" @click="getTodayData">只看当日</a-button>-->
<!--            </a-form-model-item>-->
            <a-radio-group :value="dataRange" @change="getTodayData">
              <a-radio-button value="today">当日</a-radio-button>
              <a-radio-button value="all">全部</a-radio-button>
            </a-radio-group>
            <a-form-model-item label="查询时段" prop="queryInstallDate" style="width: 30%">
              <a-range-picker v-model="queryInstallDate"></a-range-picker>
            </a-form-model-item>
            <div class="query-btns" style="float: right;width: 18%;">
              <a-button @click="resetQueryParams">重置</a-button>
              <a-button type="primary" class="after-btn" @click="getTableData(true)">查询</a-button>
            </div>
          </a-form-model>
        </div>
      </div>
    </div>
    <a-table :columns="tableColumns" :data-source="tableData" :rowKey="(record,index)=> index" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" :pagination="false">
      <span slot="disconnectiontime" slot-scope="value">
        <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
          <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
        </a-tooltip>
      </span>
      <span slot="reconnectiontime" slot-scope="value">
          <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
            <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
          </a-tooltip>
      </span>
      <span slot="offlinetime" slot-scope="value">
        <span>{{timeHandle(value)}}</span>
      </span>
    </a-table>
    <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" @change="pageChange" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
      <template slot="buildOptionText" slot-scope="props">
        <span>{{ props.value }}条/页</span>
      </template>
    </a-pagination>
  </a-modal>
</template>

<script>
import pagination from "@/mixins/pagination";
import {
  getNetworkLogListByCondition
} from "A/monitoring";
import {mapGetters, mapState} from "vuex";
import moment from "moment";
import {getTimeRange} from "U/index";

export default {
  name: "NetworkLogList",
  mixins: [pagination],
  props: {
    visible: {
      default: false
    },
    showType: {
      default: ''
    },
    detailData: {
      default: null
    },
  },
  components: {},
  data() {
    return {
      moment,
      modalVisible: false,
      queryInstallDate:null,
      dataRange:'today',
      queryParams: {
        today:true,
        starttime:'',
        endtime:'',
      },
      tableColumns: [
        {
          title: '异常终端',
          dataIndex: 'monitorname',
          key: 'monitorname',
          ellipsis: true,
        },
        {
          title: '项目名称',
          dataIndex: 'monitorpointname',
          key: 'monitorpointname',
          ellipsis: true,
        },
        {
          title: '断线时间',
          dataIndex: 'disconnectiontime',
          key: 'disconnectiontime',
          ellipsis: true,
          scopedSlots: { customRender: 'disconnectiontime' },
        },
        {
          title: '恢复时间',
          dataIndex: 'reconnectiontime',
          key: 'reconnectiontime',
          ellipsis: true,
          scopedSlots: { customRender: 'reconnectiontime' },
        },
        {
          title: '断线时长',
          dataIndex: 'offlinetime',
          key: 'offlinetime',
          ellipsis: true,
          scopedSlots: { customRender: 'offlinetime' },
        },
      ],
      tableData: [],
      tableData_Dev:[],
      tableLoading: false,
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    ...mapGetters(['uploadHeaders', 'uploadBasemap']),
    modalTitle() {
      if (this.showType == 'networkLog') {
        return '网络报告'
      } else if(this.showType == 'networkDetail'){
        return '网络报告详情'
      }else {
        return '';
      }
    }
  },
  watch: {
    visible(val) {
      if (val) {
        this.modalVisible = true;
        this.getTableData(true);
      }
    },
    modalVisible(val) {
      if (!val) {
        this.dataRange='today'
        this.queryParams.today=true
        this.$emit('update:visible', false);
      }
    },
    queryInstallDate(val) {
      let {start, end} = getTimeRange(val);
      this.queryParams.starttime = start;
      this.queryParams.endtime = end;
    },
  },
  created() {
    this.modalVisible = this.visible;
  },
  methods: {
    resetQueryParams() {
      this.queryInstallDate = [];
      this.$refs.queryForm.resetFields();
    },
    getTodayData(e){
      if(e.target.value=='all'){
        this.dataRange='all'
        this.queryParams.today=''
        this.getTableData(true)
      }else if(e.target.value=='today'){
        this.dataRange='today'
        this.queryParams.today=true
        this.getTableData(true)
      }
    },
    getTableData(firstPage) {
      if (firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      let params = {
        ...this.queryParams,
        monitorpointnum:this.showType == 'networkDetail'?this.detailData:'',
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize
      };
      getNetworkLogListByCondition(params).then(res => {
        this.tableLoading = false;
        if (res && res.returncode == '0') {
          this.tableData = res.item;
          this.pagination.total = res.count;
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    timeHandle(time){
      let returnTime;
      let day;
      let hour;
      let minutes;
      let seconds;
      if(time>86400) {
        day = Math.floor(time / 86400);
        returnTime=day + "天";
        if (time % 86400 > 3600) {
          hour = Math.floor(time % 86400 / 3600);
          returnTime+=hour + "时";
          if (time % 86400 % 3600 > 60) {
            minutes = Math.floor(time % 86400 % 3600 / 60);
            returnTime+=minutes + "分";
            seconds = Math.floor(time % 86400 % 3600 % 60);
            returnTime+=seconds + "秒";
          } else {
            seconds = Math.floor(time % 86400 % 3600 % 60);
            returnTime+=seconds + "秒";
          }
        } else {
          if (time % 86400 > 60) {
            minutes = Math.floor(time % 86400 / 60);
            returnTime+=minutes + "分";
            seconds = Math.floor(time % 86400 % 60);
            returnTime+=seconds + "秒";
          } else {
            seconds = Math.floor(time % 86400);
            returnTime+=seconds + "秒";
          }
        }
      }else if(time>=3600&&time<86400){
        let hour = Math.floor(time % 86400 / 3600);
        returnTime=hour + "时";
        if (time % 3600 > 60) {
          minutes = Math.floor(time % 3600 / 60);
          returnTime+=minutes + "分";
          seconds = Math.floor(time % 3600 % 60);
          returnTime+=seconds + "秒";
        } else {
          seconds = Math.floor(time % 3600 % 60);
          returnTime+=seconds + "秒";
        }
      }else if(time>=60&&time<3600){
        minutes = Math.floor(time / 60);
        returnTime=minutes + "分";
        seconds = Math.floor(time % 60);
        returnTime+=seconds + "秒";
      }else{
        returnTime=time + "秒";
      }
      return returnTime
    },
  }
}
</script>

<style scoped>

</style>