<template>
  <a-modal v-model="modalVisible" :title="modalTitle" :footer="false" :width="1280" :centered="true">
    <div class="menu-modal-container">
      <a-table :columns="tableColumns" :customRow="customRow" :data-source="tableData" :rowKey="(record,index)=> index" :scroll="{ x: 1280 }" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" :pagination="false">
                <span slot="eventstarttime" slot-scope="value">
          <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
            <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
          </a-tooltip>
        </span>
        <span slot="systemnameid" slot-scope="value,record">{{systemNamesMap[value]?systemNamesMap[value]:''}}</span>
        <span slot="writetime" slot-scope="value">
          <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
            <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
          </a-tooltip>
        </span>
        <span slot="arrivetime" slot-scope="value">
          <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
            <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
          </a-tooltip>
        </span>
        <span slot="timerange" slot-scope="value,record">
            <span>{{value?value+"分钟":""}}</span>
          </span>
        <span slot="feedback" slot-scope="value">
            <span>{{feedbackList[value-1]?feedbackList[value-1].dicvalue:''}}</span>
          </span>
        <span slot="eventname" :title="value?(value+(record.thirdeventname?('('+record.thirdeventname+')'):'')):''" slot-scope="value, record">{{value?(value+(record.thirdeventname?('('+record.thirdeventname+')'):'')):''}}</span>
        <span slot="eventstarttime" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
              <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
            </a-tooltip>
          </span>
        <span slot="monitorpointname" slot-scope="value, record">
            <span v-if="!record.checkeraccount||!record.checkeraccount.includes(userInfo.useraccount)" class="table-dot" style="background-color: #ff4d4f;"></span>
            <a-tooltip :title="value">
              <span>{{value}}</span>
            </a-tooltip>
          </span>
        <span slot="biginfluence" slot-scope="value">
            <span>{{value?bigInfluences[value-1].dicvalue:''}}</span>
          </span>
        <span slot="causeclassify" slot-scope="value">
            <span>{{value?causeClassifyList[value-1]?causeClassifyList[value-1].dicvalue:'':''}}</span>
          </span>
        <span slot="plannedwork" slot-scope="value">
            <span>{{value==0?'否':'是'}}</span>
          </span>
        <span slot="abnormaltype" slot-scope="value">
            <span>{{abnormalTypesMap[value]}}</span>
          </span>
        <span slot="checkrecord" slot-scope="value,record">
            <a-button type="primary" size="small" @click="addCheckBackData(value, record)">已阅</a-button>
          </span>
        <span slot="checkrecords" slot-scope="value,record">
            <a-button type="link" size="small" @click="checkRecords(value, record)">查看</a-button>
          </span>
      </a-table>
      <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" @change="pageChange" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
        <template slot="buildOptionText" slot-scope="props">
          <span>{{ props.value }}条/页</span>
        </template>
      </a-pagination>
    </div>
    <a-modal :visible="checkRecordsVisible" title="查阅记录" @cancel="checkRecordsVisible=false" :footer="null" width="800px">
      <a-table :columns="columns" :rowKey="(record,index)=> index" :data-source="checkData" style="width: 750px">
        <span slot="checktime" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
              <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
            </a-tooltip>
          </span>
      </a-table>
    </a-modal>
    <a-modal :visible="detailVisible" :title="modalTitle" @cancel="detailVisible=false" :footer="null" :width="1200">
      <a-form-model ref="queryForm" :model="detailParams" layout="inline" class="query-form">
        <a-form-model-item label="事件编号" prop="eventnum" style="width: 30%">
          <a-input placeholder="系统自动生成" style="width: 200px;" v-model="detailParams.eventnum" :disabled="true"></a-input>
        </a-form-model-item>
        <a-form-model-item v-if="this.showType==='unhandleAlarm'||this.showType==='alarm'" label="系统名称(场景)" prop="systemnameid" style="width: 30%">
          <a-select default-value="" v-model="detailParams.systemnameid" placeholder="" style="width: 200px;" :disabled="true">
            <a-select-option v-for="(item, index) in systemNames" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item v-if="this.showType==='unhandleAlarm'||this.showType==='alarm'" label="报警时间" prop="eventstarttime" style="width: 30%">
          <a-date-picker show-time placeholder="" v-model="detailParams.eventstarttime" style="width: 200px;" :disabled="true"/>
        </a-form-model-item>
        <a-form-model-item v-if="this.showType==='unhandleAlarm'||this.showType==='alarm'" label="报警类型" prop="eventcode" style="width: 30%">
          <a-select v-model="detailParams.eventcode" placeholder="" style="width: 200px;" :disabled="true">
            <a-select-option v-for="(item, index) in alarmTypes" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item v-if="detailParams.systemnameid==7||detailParams.systemnameid==8" label="报警子类型" prop="eventchildcode" style="width: 30%">
          <a-select v-model="detailParams.eventchildcode" placeholder="" style="width: 200px;" :disabled="true">
            <a-select-option v-for="(item, index) in alarmChildTypes" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item v-if="this.showType==='unhandleAbnormal'||this.showType==='abnormal'" label="事件类型" prop="eventcode" style="width: 30%">
          <a-select v-model="detailParams.eventcode" style="width: 200px;" :disabled="true">
            <a-select-option v-for="(item, index) in abnormalTypes" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="重大影响" prop="biginfluence" style="width: 30%">
          <a-select v-model="detailParams.biginfluence" style="width: 200px;" :disabled="true">
            <a-select-option v-for="(item, index) in bigInfluences" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item v-if="this.showType==='unhandleAbnormal'||this.showType==='abnormal'" label="管理类型" prop="managetype" style="width: 30%">
          <a-input v-model.trim="detailParams.managetype" placeholder="" style="width: 200px;" :disabled="true"></a-input>
        </a-form-model-item>
        <a-form-model-item v-if="this.showType==='unhandleAbnormal'||this.showType==='abnormal'" label="发生时间" prop="eventstarttime" style="width: 30%">
          <a-date-picker v-model="detailParams.eventstarttime" show-time placeholder="" style="width: 200px;" :disabled="true"></a-date-picker>
        </a-form-model-item>
        <a-form-model-item v-if="detailParams.biginfluence!=1" label="特别事件报告编号" prop="specialeventnum" style="width: 30%">
          <a-input v-model="detailParams.specialeventnum" placeholder="" style="width: 200px;" :disabled="true"></a-input>
        </a-form-model-item>
        <a-form-model-item label="编号/位置" prop="location" style="width: 30%">
          <a-textarea v-model="detailParams.location" placeholder="" cols="24" rows="2" :disabled="true"></a-textarea>
        </a-form-model-item>
        <a-form-model-item v-if="detailParams.eventcode=='6'" label="设备类型" prop="devicetype" style="width: 30%">
          <a-cascader :disabled="true" :field-names="{ label: 'devicename', value: 'devicecode', children: 'children' }" :options="deviceOptions" v-model="deviceCascaderSelected" :load-data="deviceOnLoadChildren" placeholder="请选择设备类型" :change-on-select="true"></a-cascader>
        </a-form-model-item>
        <a-form-model-item v-if="this.showType==='unhandleAlarm'||this.showType==='alarm'" label="到场人员" prop="arriveman" style="width: 30%">
          <a-input v-model="detailParams.arriveman" placeholder="" style="width: 200px;" :disabled="true"></a-input>
        </a-form-model-item>
        <a-form-model-item v-if="this.showType==='unhandleAlarm'||this.showType==='alarm'" label="到场时间" prop="arrivetime" style="width: 30%">
          <a-date-picker show-time placeholder="" v-model="detailParams.arrivetime" style="width: 200px;" :disabled="true"/>
        </a-form-model-item>
        <a-form-model-item v-if="this.showType==='unhandleAlarm'||this.showType==='alarm'" label="确警时长" prop="timerange" style="width: 30%">
          <a-input v-model="detailParams.timerange" addon-after="分钟" placeholder="" style="width: 200px" :disabled="true"></a-input>
        </a-form-model-item>
        <a-form-model-item v-if="this.showType==='unhandleAlarm'||this.showType==='alarm'" label="到场反馈" prop="feedback" style="width: 30%">
          <a-select v-model="detailParams.feedback" placeholder="" style="width: 200px" :disabled="true">
            <a-select-option v-for="(item, index) in feedbackList" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item v-if="detailParams.systemnameid==1" label="原因分类" prop="causeclassify" style="width: 30%">
          <a-select v-model="detailParams.causeclassify" placeholder="" style="width: 200px" :disabled="true">
            <a-select-option v-for="(item, index) in causeClassifyList" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="报事/报修" prop="reports" style="width: 30%">
          <a-select v-model="detailParams.reports" placeholder="请选择" style="width: 200px;" :disabled="true">
            <a-select-option value="无">无</a-select-option>
            <a-select-option value="报事">报事</a-select-option>
            <a-select-option value="报修">报修</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="报事/报修单号" prop="repairorder" style="width: 30%">
          <a-input v-model="detailParams.repairorder" placeholder="" style="width: 200px" :disabled="true"></a-input>
        </a-form-model-item>
        <a-form-model-item label="计划性作业" prop="plannedwork" style="width: 30%">
          <a-select v-model="detailParams.plannedwork" placeholder="请选择" style="width: 125px" :disabled="true">
            <a-select-option :value="1">是</a-select-option>
            <a-select-option :value="0">否</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="情况描述" prop="description" style="width: 30%">
          <a-textarea v-model="detailParams.description" placeholder="" cols="32" rows="2" :disabled="true"></a-textarea>
        </a-form-model-item>
        <a-form-model-item label="处理措施" prop="measure" style="width: 30%">
          <a-textarea v-model="detailParams.measure" placeholder="" cols="32" rows="2" :disabled="true"></a-textarea>
        </a-form-model-item>
        <a-form-model-item label="记录人" prop="recordman" style="width: 30%">
          <a-input v-model="detailParams.recordman" placeholder="" style="width: 200px" :disabled="true"></a-input>
        </a-form-model-item>
        <a-form-model-item label="记录时间" prop="writetime" style="width: 30%">
          <a-date-picker v-model="detailParams.writetime" show-time placeholder="" :disabled="true" style="width: 200px;"></a-date-picker>
        </a-form-model-item>
        <a-divider />
        <a-form-model-item label="跟进结果" prop="followreturn" style="width: 30%">
          <a-textarea v-model="detailParams.followreturn" placeholder="" cols="32" rows="2" :disabled="true"></a-textarea>
        </a-form-model-item>
        <a-form-model-item label="完成标志" prop="eventstatus" style="width: 30%">
          <a-select default-value="" v-model="detailParams.eventstatus" style="width: 200px" :disabled="true">
            <a-select-option v-for="(item, index) in finishSigns" :key="index" :value="item.key">{{item.value}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="确认人" prop="confirmman" style="width: 30%">
          <a-input v-model="detailParams.confirmman" placeholder="" style="width: 200px" :disabled="true"></a-input>
        </a-form-model-item>
        <a-form-model-item label="确认时间" prop="confirmtime" style="width: 30%">
          <a-date-picker v-model="detailParams.confirmtime" show-time placeholder="" :disabled="true" style="width: 200px;"></a-date-picker>
        </a-form-model-item>
        <a-form-model-item label="处理时长" prop="processtime" style="width: 30%">
          <a-input v-model="detailParams.processtime" addon-after="分钟" placeholder="" :disabled="true" style="width: 200px"></a-input>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </a-modal>
</template>
<script>
import pagination from "@/mixins/pagination";
import {mapGetters, mapState} from "vuex";
import {addCheckBack, getCheckBackListByCondition, getEventrecordListByCondition} from "A/wlw";
import moment from "moment";
import {getCache} from "U/index";
import {finishSigns} from "@/json/wlwhistory";

export default {
  name: "EventRecordList",
  mixins: [pagination],
  props: {
    visible: {
      default: false
    },
    showType: {
      default: ''
    },
    detailData: {
      default: null
    },
  },
  components: {},
  data() {
    return {
      moment,
      alarmTypes:[],
      alarmChildTypes:[],
      systemNamesMap:{},
      bigInfluences:[],
      alarmTypesMap:{},
      sceneTypes:[],
      finishSigns,
      feedbackList:[],
      systemNames:[],
      causeClassifyList:[],
      abnormalTypesMap:{},
      modalVisible: false,
      queryParams: {
        monitorpointname:'',
        eventnum:'',
        eventcode:'',
        starttime:'',
        endtime:'',
        eventtype:'',
        eventstatus:1,
      },
      alarmColumns: [
        {
          title: '项目名称',
          dataIndex: 'monitorpointname',
          key: 'monitorpointname',
          ellipsis: true,
          width: 180,
          scopedSlots: { customRender: 'monitorpointname' },
        },
        {
          title: '事件编号',
          dataIndex: 'eventnum',
          key: 'eventnum',
          ellipsis: true,
          width:100
        },
        {
          title: '系统名称(场景)',
          dataIndex: 'systemnameid',
          key: 'systemnameid',
          ellipsis: true,
          scopedSlots: { customRender: 'systemnameid' },
          width: 130
        },
        {
          title: '报警类型',
          dataIndex: 'eventname',
          key: 'eventname',
          ellipsis: true,
          width: 120
        },
        {
          title: '报警子类型',
          dataIndex: 'eventchildname',
          key: 'eventchildname',
          ellipsis: true,
          width: 120
        },
        {
          title: '重大影响',
          dataIndex: 'biginfluence',
          key: 'biginfluence',
          ellipsis: true,
          scopedSlots: { customRender: 'biginfluence' },
          width: 70
        },
        {
          title: '报警时间',
          dataIndex: 'eventstarttime',
          key: 'eventstarttime',
          ellipsis: true,
          scopedSlots: { customRender: 'eventstarttime' },
          width: 150
        },
        {
          title: '记录时间',
          dataIndex: 'writetime',
          key: 'writetime',
          ellipsis: true,
          scopedSlots: { customRender: 'writetime' },
          width: 150
        },
        {
          title: '编号/位置',
          dataIndex: 'location',
          key: 'location',
          ellipsis: true,
          width: 180
        },
        {
          title: '到场人员',
          dataIndex: 'arriveman',
          key: 'arriveman',
          ellipsis: true,
          width: 70
        },
        {
          title: '到场时间',
          dataIndex: 'arrivetime',
          key: 'arrivetime',
          ellipsis: true,
          scopedSlots: { customRender: 'arrivetime' },
          width: 150
        },
        {
          title: '到场时长',
          dataIndex: 'timerange',
          key: 'timerange',
          ellipsis: true,
          width: 85,
          scopedSlots: { customRender: 'timerange' },
        },
        {
          title: '到场反馈',
          dataIndex: 'feedback',
          key: 'feedback',
          ellipsis: true,
          scopedSlots: { customRender: 'feedback' },
          width: 70
        },
        {
          title: '原因分类',
          dataIndex: 'causeclassify',
          key: 'causeclassify',
          ellipsis: true,
          align:'center',
          scopedSlots: { customRender: 'causeclassify' },
          width: 80
        },
        {
          title: '计划性作业',
          dataIndex: 'plannedwork',
          key: 'plannedwork',
          ellipsis: true,
          align:'center',
          scopedSlots: { customRender: 'plannedwork' },
          width: 80
        },
        {
          title: '情况描述',
          dataIndex: 'description',
          key: 'description',
          ellipsis: true,
          width: 240
        },
        {
          title: '处理措施',
          dataIndex: 'measure',
          key: 'measure',
          ellipsis: true,
          width: 200
        },
        {
          title: '报事/报修单号',
          dataIndex: 'repairorder',
          key: 'repairorder',
          ellipsis: true,
          width: 100
        },
        {
          title: '查阅人',
          dataIndex: 'checker',
          key: 'checker',
          ellipsis: true,
          align:'center',
          width: 150,
        },
        {
          title: '查阅',
          dataIndex: 'checkrecord',
          key: 'checkrecord',
          ellipsis: true,
          align:'center',
          width: 70,
          scopedSlots: { customRender: 'checkrecord' },
        },
        {
          title: '查阅记录',
          dataIndex: 'checkrecords',
          key: 'checkrecords',
          ellipsis: true,
          align:'center',
          width: 70,
          scopedSlots: { customRender: 'checkrecords' },
        },
      ],
      abnormalColumns: [
        {
          title: '项目名称',
          dataIndex: 'monitorpointname',
          key: 'monitorpointname',
          ellipsis: true,
          width: 180,
          scopedSlots: { customRender: 'monitorpointname' },
        },
        {
          title: '事件编号',
          dataIndex: 'eventnum',
          key: 'eventnum',
          ellipsis: true,
          width:100
        },
        {
          title: '管理类型',
          dataIndex: 'managetype',
          key: 'managetype',
          ellipsis: true,
          scopedSlots: { customRender: 'managetype' },
          width: 150
        },
        {
          title: '发生时间',
          dataIndex: 'eventstarttime',
          key: 'eventstarttime',
          ellipsis: true,
          scopedSlots: { customRender: 'eventstarttime' },
          width: 150
        },
        {
          title: '事件类型',
          dataIndex: 'eventname',
          key: 'eventname',
          ellipsis: true,
          width: 70
        },
        {
          title: '重大影响',
          dataIndex: 'biginfluence',
          key: 'biginfluence',
          ellipsis: true,
          scopedSlots: { customRender: 'biginfluence' },
          width: 70
        },
        {
          title: '编号/位置',
          dataIndex: 'location',
          key: 'location',
          ellipsis: true,
          width: 120
        },
        {
          title: '计划性作业',
          dataIndex: 'plannedwork',
          key: 'plannedwork',
          ellipsis: true,
          align:'center',
          scopedSlots: { customRender: 'plannedwork' },
          width: 80
        },
        {
          title: '情况描述',
          dataIndex: 'description',
          key: 'description',
          ellipsis: true,
          width: 240
        },
        {
          title: '处理措施',
          dataIndex: 'measure',
          key: 'measure',
          ellipsis: true,
          width: 220
        },
        {
          title: '报事/报修单号',
          dataIndex: 'repairorder',
          key: 'repairorder',
          ellipsis: true,
          width: 100
        },
        {
          title: '查阅人',
          dataIndex: 'checker',
          key: 'checker',
          ellipsis: true,
          align:'center',
          width: 150,
        },
        {
          title: '查阅',
          dataIndex: 'checkrecord',
          key: 'checkrecord',
          ellipsis: true,
          align:'center',
          width: 70,
          scopedSlots: { customRender: 'checkrecord' },
        },
        {
          title: '查阅记录',
          dataIndex: 'checkrecords',
          key: 'checkrecords',
          ellipsis: true,
          align:'center',
          width: 70,
          scopedSlots: { customRender: 'checkrecords' },
        },
      ],
      tableColumns: [],
      tableData: [],
      tableLoading: false,
      checkRecordsVisible:false,
      checkData:[],
      columns:[
        {
          title: '查阅者姓名',
          dataIndex: 'checkername',
          key: 'checkername',
          ellipsis: true,
        },
        {
          title: '联系电话',
          dataIndex: 'checkerphone',
          key: 'checkerphone',
          ellipsis: true,
        },
        {
          title: '查阅时间',
          dataIndex: 'checktime',
          key: 'checktime',
          ellipsis: true,
          scopedSlots: { customRender: 'checktime' },
        },
      ],
      detailVisible:false,
      detailParams:{},
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    ...mapGetters(['uploadHeaders', 'uploadBasemap']),
    modalTitle() {
      if (this.showType == 'unhandleAlarm') {
        return '未处理报警列表'
      } else if (this.showType == 'unhandleAbnormal') {
        return '未处理异常列表'
      } else if (this.showType == 'alarm') {
        return '报警条目详情'
      } else if (this.showType == 'abnormal') {
        return '异常条目详情'
      }else {
        return '';
      }
    }
  },
  watch: {
    visible(val) {
      if (val) {
        this.modalVisible = true;
        let dictionary = getCache('dictionary', true);
        if(dictionary) {
          this.bigInfluences = dictionary.bigInfluences;
          this.alarmTypes = dictionary.alarmTypes;
          this.alarmTypesMap = dictionary.alarmTypesMap;
          this.alarmChildTypes = dictionary.alarmChildTypes;
          this.systemNames = dictionary.systemNames;
          this.systemNamesMap = dictionary.systemNamesMap;
          this.feedbackList = dictionary.feedbackList;
          this.abnormalTypes = dictionary.abnormalTypes;
          this.abnormalTypesMap = dictionary.abnormalTypesMap;
          this.equipmentTypes = dictionary.equipmentTypes;
          this.manageTypes = dictionary.manageTypes;
          this.recordTypes = dictionary.recordTypes;
          this.causeClassifyList = dictionary.causeClassifyList;
          this.sceneTypes = dictionary.sceneTypes;
        }
        this.getTableData(true);
      }
    },
    modalVisible(val) {
      if (!val) {
        this.$emit('update:visible', false);
      }
    }
  },
  created() {
    this.modalVisible = this.visible;
  },
  methods: {
    resetQueryForm() {
      this.$refs.queryForm.resetFields();
    },
    getTableData(firstPage) {
      if (firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      if(this.showType=='unhandleAlarm'){
        this.tableColumns=this.alarmColumns
        this.queryParams.eventtype=901
        let params = {
          ...this.queryParams,
          userdepsid:this.detailData,
          batterycar:true,
          pageno: this.currentPage,
          pagesize: this.pagination.pageSize
        };
        getEventrecordListByCondition(params).then(res => {
          this.tableLoading = false;
          if (res && res.returncode == '0') {
            this.tableData = res.item;
            this.pagination.total = res.count;
          }
        }).catch(err => {
          this.tableLoading = false;
        })
      }else if(this.showType=='unhandleAbnormal'){
        this.tableColumns=this.abnormalColumns
        this.queryParams.eventtype=902
        let params = {
          ...this.queryParams,
          userdepsid:this.detailData,
          pageno: this.currentPage,
          pagesize: this.pagination.pageSize
        };
        getEventrecordListByCondition(params).then(res => {
          this.tableLoading = false;
          if (res && res.returncode == '0') {
            this.tableData = res.item;
            this.pagination.total = res.count;
          }
        }).catch(err => {
          this.tableLoading = false;
        })
      }else{
        if(this.showType=="alarm"){
          this.tableColumns=this.alarmColumns
        }else if(this.showType=="abnormal"){
          this.tableColumns=this.abnormalColumns
        }
        let params = {
          eventrecordid:this.detailData,
          pageno: this.currentPage,
          pagesize: this.pagination.pageSize
        };
        getEventrecordListByCondition(params).then(res => {
          this.tableLoading = false;
          if (res && res.returncode == '0') {
            this.tableData = res.item;
            this.pagination.total = res.count;
          }
        }).catch(err => {
          this.tableLoading = false;
        })
      }
    },
    checkRecords(value, record){
      this.isclickrow=true
      this.checkRecordsVisible=true
      this.getCheckBackDataList(record.eventrecordid)
    },
    addCheckBackData(value,record){
      this.isclickrow=true
      let params={
        eventrecordid:record.eventrecordid,
        checkeraccount:this.userInfo.useraccount,
        checkername:this.userInfo.username,
        checkerphone:this.userInfo.phonenum,
        usertype:this.userInfo.usertype==0?this.usertype:this.userInfo.usertype,
        checktype:0,
        managetype:this.managetype,
        checktime:moment(new Date()).format("YYYYMMDDHHmmss")
      }
      addCheckBack(params).then(res=>{
        if(res&&res.returncode=='0'){
          this.$message.success("操作成功")
          this.isclickrow=false
          this.getTableData()
        }
      })
    },
    getCheckBackDataList(eventrecordid){
      getCheckBackListByCondition({eventrecordid: eventrecordid}).then(res=>{
        if(res&&res.returncode=='0'){
          this.checkData=res.item
          this.isclickrow=false
        }
      })
    },
    customRow(record, index){
      return {
        on: { // 事件
          click: () => {
            if(!this.isclickrow){
              record.eventstarttime=record.eventstarttime?moment(record.eventstarttime,'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss'):null
              record.confirmtime=record.confirmtime?moment(record.confirmtime,'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss'):null
              record.writetime=record.writetime?moment(record.writetime,'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss'):null
              record.arrivetime=record.arrivetime?moment(record.arrivetime,'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss'):null
              record.biginfluence=record.biginfluence?record.biginfluence.toString():null
              record.systemnameid=record.systemnameid?record.systemnameid.toString():null
              record.feedback=record.feedback?record.feedback.toString():null
              record.causeclassify=record.causeclassify?record.causeclassify.toString():null
              this.detailParams=record
              this.detailVisible=true
            }
          },
        },
      }
    },
  }
}
</script>
<style scoped>

</style>