<template>
  <div class="lot-big-data">
    <div class="big-bg">
<!--      <div class="big-bg" :style="bgImg">-->
      <img :src="picurl" style="position: absolute;background-size: cover;width: 100%;height: 100%" />
      <div class="header">
        <MiddleTop style="margin-top: 2%" />
        <div class="fix-right">
          <div class="fr" style="cursor: pointer;">
            <a-icon v-if="!fullScreen" type="fullscreen" @click="requestFullScreen('enter')" />
            <a-icon v-else type="fullscreen-exit" @click="requestFullScreen" />
          </div>
          <div class="fr">
            <div>{{currArr[0]}}</div>
            <div class="lg-size">{{currArr[1]}}</div>
          </div>
        </div>
      </div>
      <div class="left">
        <MiddleLeft/>
      </div>
      <div class="right">
        <MiddleRight/>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import {mapState} from "vuex";
import MiddleTop from "V/bigData/projectBigData/MiddleTop/Index";
import MiddleLeft from "V/bigData/projectBigData/MiddleLeft/Index";
import MiddleRight from "V/bigData/projectBigData/MiddleRight/Index";
import store from "@/store";
import {getMonitorPointNameList} from "A/monitoring";
import {getBasemapListByCondition} from "A/patrol";

export default {
  name: "projectBigData",
  components: {
    MiddleTop,
    MiddleLeft,
    MiddleRight,
  },
  data() {
    return {
      currArr:[],
      dateArr:[],
      timer1:null,
      timer2:null,
      baseurl:"https://fda.u-lake.com/",
      picurl:"",
      monitorpointnum:'',
      bgImg:{ backgroundImage:"url(" + require("../../../assets/image/baolifazhan.png") + ")",
        height:'100vh',//这里一定要设置高度 否则背景图无法显示
        backgroundRepeat: "no-repeat"},
    }
  },
  computed: {
    ...mapState({
      fullScreen: state => state.main.fullScreen,
      fontSize: state => state.main.fontSize,
      collapsed: state => state.main.collapsed,
      userInfo: state => state.userInfo
    })
  },
  mounted () {
    this.initDate()
    function screenChange(e){
      store.commit('main/setFullScreen', document.fullscreen)
    }
    if(document.body.clientWidth>1360){
      store.commit('main/setFontSize', 18)
    }
    window.addEventListener('fullscreenchange',screenChange)
    this.$once('hook:beforeDestroy',()=>{
      clearTimeout(this.timer1)
      clearTimeout(this.timer2)
      window.removeEventListener('fullscreenchange',screenChange)
    })
    let that = this;
    //开始背景图片自适应
    that.setImgSize();
    //屏幕尺寸改变的监听方法
    window.onresize = () => {
      that.setImgSize();
    }
  },
  created() {
    this.getMonitorpointName()
  },
  methods:{
    getMonitorpointName(){
      getMonitorPointNameList({userdepid:this.userInfo.userdepid}).then(res=>{
        if(res&&res.returncode=='0'){
          this.monitorpointnum=res.item[0].monitorpointnum
          getBasemapListByCondition({monitorpointnum:this.monitorpointnum}).then(res=>{
            if(res&&res.returncode=='0'){
              this.picurl=this.baseurl+res.item[0].basemapurl
            }
          })
        }
      })
    },
    initDate(){
      this.currArr = this.showDT()
      let timer = setInterval(()=>{
        this.currArr = this.showDT()
      },1000);
      this.$once('hook:beforeDestroy',()=>{
        clearInterval(timer);
        timer = null;
      })
    },
    showDT() {
      return [moment().format("YYYY.MM.DD"),moment().format("HH:mm:ss")]
    },
    initDateArr(date){
      let arr = []
      arr.push(moment(date?new Date(date):new Date()).subtract(2,'months').format('YYYY.MM'))
      arr.push(moment(date?new Date(date):new Date()).subtract(1,'months').format('YYYY.MM'))
      arr.push(moment(date?new Date(date):new Date()).format('YYYY.MM'))
      let date1 = moment(date?new Date(date):new Date()).subtract(-1,'months')
      arr.push(date1<moment()?date1.format('YYYY.MM'):'')
      let date2 = moment(date?new Date(date):new Date()).subtract(-2,'months')
      arr.push(date2<moment()?date2.format('YYYY.MM'):'')
      let currDate = moment(date?new Date(date):new Date()).format('YYYYMM')
      this.dateArr = arr
    },
    setImgSize(){
      let that=this
      let height = document.body.clientHeight+"px"; //获取可见区域的高
      let width = document.body.clientWidth+"px";//获取可见区域的宽
      that.$set(that.bgImg,'backgroundSize',width+""+height);
    },
    // 控制是否全屏展示
    requestFullScreen(type) {
      if(type==='enter'){
        let element = document.body
        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else if (element.msRequestFullscreen) {
          element.msRequestFullscreen();
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen();
        } else if (element.webkitRequestFullScreen) {
          element.webkitRequestFullScreen();
        }
      }else{
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen()
        }
      }
    }
  }
}
</script>

<style scoped>
.lot-big-data {
  position: relative;
  font-size: 16px;
  color: #fff;
  height: 100%;
  padding: 0;
  overflow: hidden;
}
.big-bg{
  position: absolute;
  top:0;
  left:0;
  right:0;
  bottom: 0;
  opacity: 0.9;
  background-size: 100% 100%;
  z-index: 10;
  pointer-events: none;
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100px;
  text-align:center;
  position: relative;
  background-color: rgb(70, 126, 198);
  padding: 0 10px;
  z-index: 10;
  pointer-events:visible;
}
.fix-right{
  width: 30%;
  font-family: ds-digitalnormal;
  color: #a4bbd8;
  font-size: 28px;
  line-height: 1.2em;
}
.fr{
  float: right;
}
.lg-size{
  font-size: 40px;
}
.left,.right{
  /*position: relative;*/
  /*float: left;*/
  position: absolute;
  bottom: 0px;
  /*left: 16px;*/
  width: 100%;
  height: 20px;
  text-align: center;
  overflow: hidden;
  height: calc(50% - 63px);
  z-index: 1000000;
  padding: 0 10px;
}
.left{
  width: 50%;
  left: 0px;
  padding: 0;
  pointer-events: none;
}
.right{
  width: 50%;
  right: 10px;
  padding: 0;
  pointer-events: none;
}
</style>