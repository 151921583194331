<template>
  <div class="bar" id="bar_left" style="height: 95%;width: 100%"></div>
</template>

<script>
import * as echarts from 'echarts';
import {getAlarmAndAbnormalNum} from "A/wlw";
export default {
  name: "Index",
  data() {
    return {
      xDataList:[],
      xLabelList:[],
      xLabelMap:{},
      maxXNum:null,
      xInterval:null,
      yDataList:[],
      total:'',
      maxYNum:null,
      yInterval:null,
    }
  },
  mounted(){
    let that = this;
    this.timers = setInterval(()=>{
      that.getAlarmAndAbnormalNumData()
    },5*60*1000)
  },
  beforeDestroy(){
    clearInterval(this.timers)
    this.timers=null
  },
  created() {
    this.getAlarmAndAbnormalNumData()
  },
  methods:{
    getAlarmAndAbnormalNumData(){
      getAlarmAndAbnormalNum({}).then(res=>{
        if(res&&res.returncode=='0') {
          this.resetEchartsData()
          if (res.alarmList.length > 0) {
            for (let i = 0; i < res.alarmList.length; i++) {
              this.xDataList.push(res.alarmList[i].num)
              this.xLabelList.push(res.alarmList[i].eventname)
            }
            this.maxXNum = res.alarmList[0].num
            this.total = res.alarmList[0].total

            let base = 5
            while (this.maxXNum > base) {
              base = base + 5
            }
            this.maxXNum = base
            this.xInterval = this.maxXNum / 5
            this.calcData()
            let that = this
            setTimeout(() => {
              that.initCharts()
            }, 1000)
          } else {
            let that = this
            setTimeout(() => {
              that.initCharts()
            }, 1000)
          }
        }
      })
    },
    calcData(){
      let totalNum=this.total
      let yData=0;
      // for(let i=0;i<this.xDataList.length;i++){
      //   totalNum+=parseInt(this.xDataList[i])
      // }
      for(let i=0;i<this.xDataList.length;i++){
        yData+=this.xDataList[i]/totalNum*100
        this.yDataList.push(yData.toFixed(2))
      }
    },
    resetEchartsData(){
      this.xDataList=[]
      this.xLabelList=[]
      this.xLabelMap={}
      this.maxXNum=null
      this.xInterval=null
      this.yDataList=[]
      this.maxYNum=null
      this.yInterval=null
    },
    initCharts(){
      let chartDom = document.getElementById('bar_left');
      let myChart = echarts.init(chartDom);
      let option;
      option = {
        title:{
          x:"center",
          show: true, //是否显示
          text: "报警历史（近三个月数据）",
          textStyle: {
            color: "#000000", // 主标题文字的颜色。
            fontSize: 18, // 字体大小
            fontWeight:'bold',
          },
          textAlign:"center",
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#e20937'
            }
          }
        },
        toolbox: {
          feature: {
            // dataView: { show: true, readOnly: false },
            // magicType: { show: true, type: ['line', 'bar'] },
            // restore: { show: true },
            // saveAsImage: { show: true }
          },
          iconStyle:{
            normal:{
              color:'#000000',//设置颜色
            }
          }
        },
        legend: {
          // data: ['报警情况', '增量图'],
          // textStyle:{
          //   color: '#000000',
          // }
        },
        xAxis: [
          {
            type: 'category',
            data: this.xLabelList,
            axisPointer: {
              type: 'shadow'
            },
            axisLabel:{
              show:true,
              formatter:function(value)
              {
                var ret = "";//拼接加\n返回的类目项
                var maxLength = 2;//每项显示文字个数
                var valLength = value.length;//X轴类目项的文字个数
                var rowN = Math.ceil(valLength / maxLength); //类目项需要换行的行数
                if (rowN > 1)//如果类目项的文字大于3,
                {
                  for (var i = 0; i < rowN; i++) {
                    var temp = "";//每次截取的字符串
                    var start = i * maxLength;//开始截取的位置
                    var end = start + maxLength;//结束截取的位置
                    //这里也可以加一个是否是最后一行的判断，但是不加也没有影响，那就不加吧
                    temp = value.substring(start, end) + "\n";
                    ret += temp; //凭借最终的字符串
                  }
                  return ret;
                }
                else {
                  return value;
                }
              },
              textStyle:{
                color: '#000000',
                fontWeight:'bold',
              }
            },
          }
        ],
        yAxis: [
          {
            show:false,
            type: 'value',
            // name: '报警情况',
            nameTextStyle: {color:'#000000'},
            min: 0,
            max: this.maxXNum,
            interval: this.xInterval,
            axisLabel: {
              formatter: '{value} 次',
              textStyle:{
                color: '#000000',
                fontSize: 15,
                fontWeight:'bold',
              }
            },
          },
          {
            // show:false,
            type: 'value',
            // name: '增量图',
            nameTextStyle: {color: '#ffffff'},
            min: 0,
            max: 100,
            interval: 80,
            axisLabel: {
              formatter: '{value} %',
              textStyle: {
                color: '#000000',
                fontSize: 15,
                fontWeight: 'bold',
              }
            },
            splitArea : {show : false},  //去除网格区域
            splitLine: {//去除网格线
              show: true,
              lineStyle: {
                color: ['rgb(237,125,49)'],
                width: 2,
                type: 'solid'
              }
            }
          }
        ],
        series: [
          {
            // name: '报警情况',
            type: 'bar',
            tooltip: {
              valueFormatter: function (value) {
                return value + ' 次';
              }
            },
            data: this.xDataList,
                // [80,10,4,3,2,2,1,1],
            itemStyle:{
              color:'#C83C3D',
            },
          },
          // {
          //   name: 'Precipitation',
          //   type: 'bar',
          //   tooltip: {
          //     valueFormatter: function (value) {
          //       return value + ' ml';
          //     }
          //   },
          //   data: [
          //     2.6, 5.9, 9.0, 26.4, 28.7, 70.7, 175.6, 182.2, 48.7, 18.8, 6.0, 2.3
          //   ]
          // },
          {
            // name: '增量图',
            type: 'line',
            yAxisIndex: 1,
            tooltip: {
              valueFormatter: function (value) {
                return value + ' %';
              }
            },
            data: this.yDataList,
                // [77.67,87.38,91.26,94.14,96.12,98.06,99.03]
            itemStyle: {
              color: '#8268A5',
            },
            lineStyle: {
              width: 3,
              type: 'solid',
            },
          }
        ]
      };
      option && myChart.setOption(option);
    }
  }
}
</script>

<style scoped>
.bar{
  pointer-events: auto;
}
</style>